import SolicitudServices from "../services/solicitud.services";
import Vue from "vue";
import router from "../../../router";

export default {
  async storeSolicitud({ commit }, data) {
    const response = await SolicitudServices.storeSolicitud(data);
    return response;
  },

  async DownloadFileAct({ commit, state }, payload) {
    Vue.prototype.showLoader();

    const token = router.history.current.query?.token

    const { data, status, headers } = await SolicitudServices.getFile({ token, storage: payload.route });

    if (status == 200) {
      const blob = new Blob([data], { type: headers["content-type"] });
      const file = new File([blob], 'documento', { type: headers["content-type"] });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = payload.value;
      link.click();
    }

    Vue.prototype.hideLoader();
  }
};

import { http_client } from "@/plugins/http_client";

const url = "/api/v1/catalogos/unidad-medida";
const get = async (params = {}) => await http_client(url, params, "get");
const getUnidades = async (params = {}) => await http_client(`/api/v1/unidad-medida/obtener-unidades-medida`, params, "get");
const createUnidad = async (body = {}) => await http_client(`/api/v1/unidad-medida/crear-unidad-medida`, body, "post");
const updateUnidad = async (id, params) => await http_client(`/api/v1/unidad-medida/actualizar-unidad-medida/${id}`, params, "put");

export default {
  get,
  getUnidades,
  createUnidad,
  updateUnidad
};

import { http_client } from "@/plugins/http_client";

const url = "/api/v1/facturacion";
const urlFactura = "/api/v1/comprobante";
const getFacturas = async (params = {}) =>
  await http_client(url, params, "get");

const correlativo = async (params = {}) =>
  await http_client(`${urlFactura}/correlativo`, params, "get");

const postFactura = async (params = {}) =>
  await http_client(`${urlFactura}/generar`, params, "post");

const postCreditoFiscal = async (params = {}) =>
  await http_client(`${urlFactura}/generar-fiscal`, params, "post");

const postNotaDebito = async (params = {}) =>
  await http_client(
    `${urlFactura}/generar-factura-nota-debito`,
    params,
    "post"
  );

const postNotaCredito = async (params = {}) =>
  await http_client(
    `${urlFactura}/generar-factura-nota-credito`,
    params,
    "post"
  );

const postNotaRemision = async (params = {}) =>
  await http_client(
    `${urlFactura}/generar-factura-nota-remision`,
    params,
    "post"
  );

const getActividades = async (params = {}) =>
  await http_client("/api/v1/catalogos/cod-actividad", params, "get");

const postFacturaSujeto = async (params = {}) =>
  await http_client(
    `${urlFactura}/generar-factura-sujeto-exluido`,
    params,
    "post"
  );
const postDonacion = async (params = {}) =>
  await http_client(`${urlFactura}/comprobante-donacion`, params, "post");

const postFacturaExportacion = async (params = {}) =>
  await http_client(
    `${urlFactura}/generar-factura-exportacion`,
    params,
    "post"
  );

const postDocLiquidacion = async (params = {}) =>
  await http_client(`${urlFactura}/generar-doc-liquidacion`, params, "post");

const getCodRetencion = async (params = {}) =>
  await http_client("/api/v1/catalogos/retencion-iva", params, "get");

const getTiposGeneraciones = async (params = {}) =>
  await http_client("/api/v1/catalogos/tipo-generacion-doc", params, "get");

const getDocumentosRelacionados = async (params = {}) =>
  await http_client("/api/v1/catalogos/dte-relacionado", params, "get");

const getInformacionDte = async (params = {}) =>
  await http_client("/api/v1/factura/relacionadas", params, "get");

const postGenerarComprobanteRetencion = async (params = {}) =>
  await http_client(
    `${urlFactura}/generar-comprobante-retencion`,
    params,
    "post"
  );

const postGenerarComprobanteLiquid = async (params = {}) =>
  await http_client(
    `${urlFactura}/generar-comprobante-liquidacion`,
    params,
    "post"
  );

// catalogo de tipo de donacion
const getCtlTipoDonacion = async (params = {}) =>
  await http_client("/api/v1/catalogos/tipo-donacion", params);
const getCtlTipoDocAsociado = async (params = {}) =>
  await http_client("/api/v1/catalogos/tipo-doc-asociado", params);
const getCtlDomicilio = async (params = {}) =>
  await http_client("/api/v1/catalogos/domicilio-fiscal", params);

export default {
  getFacturas,
  correlativo,
  postFactura,
  postCreditoFiscal,
  getActividades,
  postFacturaSujeto,
  postDonacion,
  getCodRetencion,
  getTiposGeneraciones,
  getDocumentosRelacionados,
  getInformacionDte,
  postGenerarComprobanteRetencion,
  postGenerarComprobanteLiquid,
  getCtlTipoDonacion,
  getCtlTipoDocAsociado,
  postDocLiquidacion,
  getCtlDomicilio,
  postNotaDebito,
  postNotaCredito,
  postNotaRemision,
  postDocLiquidacion,
  postFacturaExportacion,
};

import SolicitudServices from "../../modules/solicitud/services/solicitud.services";
import authServices from "../../services/auth.services";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    alert: {
      show: false,
      type: "info",
      message: "",
      timeout: 2500,
    },
    loader: false,
    modal_loader: false,
    sideBar: true,
    menu: [],
    rutas: [],
    filters: {
      tipo_busqueda: null,
      valor_busqueda: null,
    }
  },
  getters: {
    getAlert: (state) => {
      return state.alert;
    },
  },
  mutations: {
    setAlert: (state, payload) => {
      state.alert = payload;
    },
    setLoader: (state, payload) => {
      state.loader = payload;
    },
    setSideBar: (state, payload) => {
      state.sideBar = payload;
    },
    setMenu: (state, menu) => {
      state.menu = menu;
    },
    setRutas: (state, rutas) => {
      state.rutas = rutas;
    },
    setModalLoader: (state, payload) => {
      state.modal_loader = payload;
    },
    setFilters: (state, payload) => {
      state.filters = payload;
    },
  },
  actions: {
    getMenu: async ({ commit }) => {
      const response = await authServices.getRutas();
      const menu = getNavItem(response?.data);
      commit("setMenu", menu);
      commit("setRutas", response.data);
    },

    async DownloadFileAct(_, payload) {
      Vue.prototype.showLoader();

      const { data, status, headers } = await SolicitudServices.getFile({
        storage: payload.route,
      });

      if (status == 200) {
        const blob = new Blob([data], { type: headers["content-type"] });
        const file = new File([blob], "documento", {
          type: headers["content-type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = payload.value;
        link.click();
      }

      Vue.prototype.hideLoader();
    },

    clearMenu: ({ commit }) => {
      commit("setMenu", []);
      commit("setRutas", []);
    },

    // Acción para detectar si el dispositivo es móvil
    detectMobile({ commit }) {
      const isMobile = window.innerWidth <= 768;
      commit("setSideBar", !isMobile);
    },
  },
};

const getNavItem = (items) => {
  return items.filter((item) => {
    if (!item.mostrar) return false;
    if (item.childrens?.length > 0) item.childrens = getNavItem(item.childrens);
    return true;
  });
};

import { http_client } from "@/plugins/http_client";

const getListInvent = async (params = {}) => await http_client('/api/v1/inventario', params);
const getInventById = async (id_inventario, params = {}) => await http_client(`/api/v1/inventario/${id_inventario}`, params);
const postNewInvent = async (params = {}) => await http_client('/api/v1/inventario', params, "post");

// const getListProvs = async (params = {}) => await http_client('/api/v1/proveedores', params);
const getListProvs = async (params = {}) => await http_client('/api/v1/proveedores/obtener-proveedores', params);

const getListProdsComp = async (id_inventario, params = {}) => await http_client(`/api/v1/cierre-inventario/productos-comprados/${id_inventario}`, params);
const getListProdsVend = async (id_inventario, params = {}) => await http_client(`/api/v1/cierre-inventario/productos-vendidos/${id_inventario}`, params);
const getListProdsMerm = async (id_inventario, params = {}) => await http_client(`/api/v1/cierre-inventario/productos-mermados/${id_inventario}`, params);
const postProdMermado = async (id_inventario, params = {}) => await http_client(`/api/v1/cierre-inventario/mermar-compra/${id_inventario}`, params, 'patch');
const postCierreInvent = async (id_inventario, params = {}) => await http_client(`/api/v1/cierre-inventario/cierre-inventario/${id_inventario}`, params, 'patch');

export default {
    getListInvent,
    getInventById,
    postNewInvent,
    getListProvs,
    getListProdsComp,
    getListProdsVend,
    getListProdsMerm,
    postProdMermado,
    postCierreInvent,
}
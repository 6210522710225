import { http_client } from "@/plugins/http_client";

const getProveedores = async (params = {}) => await http_client(`api/v1/proveedores/obtener-proveedores`, params, "get");
const crearProveedor = async (body = {}) => await http_client(`api/v1/proveedores/crear-proveedor`, body, "post");
const editarProveedor = async (id, params) => await http_client(`api/v1/proveedores/editar-proveedor/${id}`, params, "put");

export default {
    getProveedores,
    crearProveedor,
    editarProveedor
};
import Vue from "vue";

/* CASO ESPECIAL PARA LOS TRIBUTOS DE TIPO 2 MAPEO */
const tributosCuerpoDocumento = (producto) => {
  return {
    producto_id: producto.id_producto,
    numero_documento: producto?.numero_documento,
    tipo_item: producto.tipo_item,
    cantidad: producto.cantidad,
    descuento: 0,
    codigo: producto.codigo,
    cod_tributo_id: producto.cod_tributo_id,
    unidad_medida: producto.unidad_medida,
    descripcion: producto.descripcion,
    precio_unitario: producto.precio_unitario,
    venta_no_sujeta: producto.no_sujetas,
    venta_exenta: producto.exentas,
    venta_gravada: producto.gravadas,
    psv: 0,
    iva_item: 0,
    no_gravado: 0,
    tributo_especial: true,
    tributos: producto.tributos,
  };
};

export default {
  LoadDetalleFactura({ commit, state }) {
    const data = state.list_productos
      .filter((item) => !item?.tributo_especial)
      .map(function (item) {
        return {
          movimiento_id: item.movimiento_id,
          descripcion: item.descripcion,
          precio_venta: item.precio_unitario,
          precio_unitario: item.precio_unitario,
          cantidad: item.cantidad,
          producto_id: item.id_producto,
          subTotal: item.subTotal,
        };
      });

    return data;
  },
};

import { http_client } from "@/plugins/http_client";

const url = "/api/v1/catalogos/tipo-documento";
const getTiposDocumentos = async (params = {}) =>
  await http_client(url, params, "get");

const url2 = "/api/v1/catalogos/tipo-doc-receptor";
const getTiposDocumentosReceptor = async (params = {}) =>
  await http_client(url2, params, "get");

const getTypeDocsAprov = async (params = {}) => await http_client("/api/v1/solicitud-documentos/obtenerAprobados", params);

export default {
  getTiposDocumentos,
  getTiposDocumentosReceptor,
  getTypeDocsAprov
};

export default [
  {
    path: "/facturacion",
    name: "facturacionList",
    component: () =>
      import(
            /* webpackChunkName: "facturacionList" */ "../views/facturacionList.vue"
      ),
  },
  // {
  //   path: "/facturacion/crear",
  //   name: "facturacionCreate",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "facturacionShow" */ "../views/facturacionForm.vue"
  //     ),
  // },
  {
    path: "/facturacion/crear",
    name: "facturacionCreate",
    component: () =>
      import(
        /* webpackChunkName: "facturacionShow" */ "../views/mainFacturacionView.vue"
      ),
  },
];

import { http_client } from "@/plugins/http_client";

const url = "/api/v1/catalogos/tipo-generacion-doc";
const get = async (params = {}) => await http_client(url, params, "get");
const store = async (body) => await http_client(`${url}/crear`, body, "post");
const getById = async (id) => await http_client(`${url}/${id}`);
const update = async (id, body) =>
  await http_client(`${url}/${id}`, body, "put");

export default {
  get,
  store,
  getById,
  update,
};

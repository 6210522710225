import usuariosRouter from "./usuarios/usuariosRouter";
import establecimientosRouter from "./establecimientos/establecimientosRouter";
import puntosVentaRouter from "./puntosVenta/puntosVentaRouter";
import productosRouter from "./productos/productosRouter";
import tributosRouter from "./tributos/router";
import descuentosRouter from "./descuentos/descuentosRouter";
import unidadesMedidaRouter from "./unidadesMedida/unidadesMedidaRouter";
import tipoProductoRouter from "./tipoProducto/tipoProductoRouter";
import proveedoresRouter from "./proveedores/proveedoresRouter";

export default [
  ...usuariosRouter,
  ...establecimientosRouter,
  ...puntosVentaRouter,
  ...productosRouter,
  ...tributosRouter,
  ...descuentosRouter,
  ...unidadesMedidaRouter,
  ...tipoProductoRouter,
  ...proveedoresRouter,
];

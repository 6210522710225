export default () => ({
  establecimientos: [],
  tiposDocumentos: [],
  filters: {
    page: 1,
    perPage: 10,
    totalRows: 0,
  },

  ctl_departamentos_st: [],
  step_1_st: {},
  step_2_st: {},

  total_acc_create: 0,
  total_acc_update: 0,

  register_type: null,
  ctl_actividades: [],
  ctl_tipo_documento: [],
  
  origin: true, // variable para saber si la solicitud es de creacion de coordinador o no
});

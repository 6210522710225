export default {
    namespaced: true,
    state: {
        show_form: false,
        selected_item: {},
    },
    mutations: {
        setShowForm: (state, payload) => {
            state.show_form = payload;
        },
        setSelectedItem: (state, payload) => {
            state.selected_item = payload;
        },
    },
}
import puntosVentaServices from "./puntosVenta.services";
import establecimientosServices from "./establecimientos.services";
import departamentosServices from "./departamentos.services";
import tipoEstablecimientoServices from "./tipoEstablecimiento.services";
import usuariosServices from "./usuarios.services";
import tipoDocumentoServices from "./tipoDocumento.services";
import productosServices from "./productos.services";
import tributosServices from "./tributos.services";
import tiposProductoServices from "./tiposProducto.services";
import unidadesMedidaServices from "./unidadesMedida.services";
import tipoGeneracionServices from "./tipoGeneracion.services";
import bienesTitulosServices from "./bienesTitulos.services";
import incotermsServices from "./incoterms.services";
import regimenServices from "./regimen.services";
import tipoRecintoServices from "./tipoRecinto.services";
import tiposPersonaServices from "./tiposPersona.services";
import transporteServices from "./transporte.services";
import docAsociadoServices from "./docAsociado.services";
import tipoContribuyenteServices from "./tipoContribuyente.services";
import descuentosServices from "./descuentos.services";
import tiposDescuentosServices from './tiposDescuentos.services'
import domicilioFiscalServices from "./domicilioFiscal.services"
import proveedoresServices from "./proveedores.services";

export default {
  puntosVentaServices,
  establecimientosServices,
  departamentosServices,
  tipoEstablecimientoServices,
  usuariosServices,
  tipoDocumentoServices,
  productosServices,
  tributosServices,
  tiposProductoServices,
  unidadesMedidaServices,
  tipoGeneracionServices,
  bienesTitulosServices,
  incotermsServices,
  regimenServices,
  tipoRecintoServices,
  tiposPersonaServices,
  transporteServices,
  docAsociadoServices,
  tipoContribuyenteServices,
  descuentosServices,
  tiposDescuentosServices,
  domicilioFiscalServices,
  proveedoresServices,
};

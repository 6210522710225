export default [
  {
    path: "establecimientos",
    name: "establecimiento",
    component: () =>
      import(
        /* webpackChunkName: "gestionEstablecimientos" */ "./establecimientos.vue"
      ),
  },
];

import Vue from "vue";
import store from "../store";

const http_client = async (
    url,
    data = {},
    method = "get",
    headers = {},
    sendToken = true,
    responseType = '',
) => {
    const token = store.state.token
        ? store.state.token
        : localStorage.getItem("token");

    if (token && sendToken) headers.Authorization = "Bearer " + token;

    let config = {
        method,
        url,
        headers,
        responseType,
    };
    if (method === "get") {
        config.params = data;
    } else {
        config.data = data;
    }
    try {
        return await Vue.prototype.axios(config);
    } catch (e) {
        if (e.response.status === 500) {
            Vue.prototype.temporalAlert({
                show: true,
                message: e.response.data.message
                    || e.response.data.description
                    || e.response.data.error
                    || "Su solicitud no ha podido ser procesada, vuelva a intentar. Si el error persiste, intente más tarde.",
                type: "error",
            });
        } else if (e.response.status === 422) {
            Vue.prototype.temporalAlert({
                show: true,
                message: e.response.data.message
                    || e.response.data.description
                    || e.response.data.error
                    || "No se pudo procesar la entidad",
                type: "error",
            });
        } else if (e.response.status === 404) {
            Vue.prototype.temporalAlert({
                show: true,
                message: e.response.data.message
                    || e.response.data.description
                    || e.response.data.error
                    || "No se encontró el recurso",
                type: "error",
            });
        } else if (e.response.status === 403) {
            Vue.prototype.temporalAlert({
                show: true,
                message: e.response.data.message
                    || e.response.data.description
                    || e.response.data.error
                    || "Petición rechazada",
                type: "error",
            });
        } else if (e.response.status === 400) {
            if (e.response.data.errores && e.response.data.errores.length > 1) {
                Vue.prototype.temporalAlert({
                    show: true,
                    array_message: e.response.data.errores,
                    type: "error",
                });
            } else {
                Vue.prototype.temporalAlert({
                    show: true,
                    message: e.response.data?.error
                        || e.response.data?.errores[0]
                        || "Petición erronea",
                    type: "error",
                });
            }
        } else if (e.response.status === 401) {
            Vue.prototype.temporalAlert({
                show: true,
                message: e.response.data.error
                    || e.response.data.description
                    || e.response.data.error
                    || "Acceso no autorizado",
                type: "error",
            });
        } else {
            Vue.prototype.temporalAlert({
                show: true,
                message: e.response.data.message
                    || e.response.data.description
                    || e.response.data.error
                    || "Error al realizar petición",
                type: "error",
            });
        }
        throw e;
    }
};

Vue.prototype.http_client = http_client;

export { http_client };

import { http_client } from '@/plugins/http_client'

// urls
const url = '/api/v1/solicitud-empresa'
const urlPost = '/api/v1/usuario/registro'
const urlDocumentos = '/api/v1/solicitud-documentos'

// solicitudes empresa
const getSolicitudes = async (params = {}) => await http_client(url, params, 'get')
const postConexion = async body => await http_client('/api/v1/empresa/actualizar-datos-conexion', body, 'post')

// solicitud por nit
const getSolicitud = async nitPath => await http_client(`${url}/ver/${nitPath}`)
const getSolicitudByNit = async nit_empresa => await http_client(`${url}/ver`, { nit_empresa })
const aprobarSolicitud = async nitPath => await http_client(`${url}/aprobar/${nitPath}`, {}, 'put')
const rechazarSolicitud = async (nitPath, body) => await http_client(`${url}/rechazar/${nitPath}`, body, 'put')
const observarSolicitud = async (nitPath, body) => await http_client(`${url}/observar/${nitPath}`, body, 'put')

// solicitud por id_documento
const aprobarSolicitudDocumento = async idDocumento => await http_client(`${urlDocumentos}/aprobar/${idDocumento}`, {}, 'put')
const rechazarSolicitudDocumento = async (idDocumento, body) => await http_client(`${urlDocumentos}/rechazar/${idDocumento}`, body, 'put')
const observarSolicitudDocumento = async (idDocumento, body) => await http_client(`${urlDocumentos}/observar/${idDocumento}`, body, 'put')

// observaciones
const getObservaciones = async (token, body) => await http_client(`${url}/observaciones/${token}`, body)

// usuarios
const storeSolicitud = async body => await http_client(urlPost, body, 'post')
const storeSolicitudArchivos = async body => await http_client(`${urlPost}/archivos`, body, 'post')
const enviarSolicitud = async body => await http_client(`${urlPost}`, body, 'post')
const postUpdateSolic = async (token, body) => await http_client(`/api/v1/usuario/registro-update/${token}`, body, 'post')
const getDetailUser = async (params = {}) => await http_client('/api/v1/usuario/ver-perfil/', params)
const updateDetailUser = async body => await http_client('api/v1/usuario/actualizar-perfil', body, 'patch')
const postPersonaNatural = async body => await http_client('/api/v1/usuario/registro/persona-natural', body, 'post')
const updatePersonaNatural = async (token, body) => await http_client(`/api/v1/usuario/registro/actualizar-persona-natural/${token}`, body, 'post')
const postPersonaJuridica = async body => await http_client('/api/v1/usuario/registro/persona-juridica', body, 'post')
const updatePersonaJuridica = async (token, body) => await http_client(`/api/v1/usuario/registro/actualizar-persona-juridica/${token}`, body, 'post')
const postNewPersnNat = async body => await http_client('/api/v1/usuario/registro/registrar-nueva-persona-natural', body, 'post')
const postNewPersnJur = async body => await http_client('/api/v1/usuario/registro/registrar-nueva-persona-juridica', body, 'post')

// descarga de archivos de solicitud
const getFile = async body => await http_client('/api/v1/archivos/descargar', body, 'get', { responseType: 'blob' }, true, 'blob', false)

// documentos emitidos
const postDocEmitted = async body => await http_client(`/api/v1/documentos-emitir/crear`, body, 'post')
const deleteDocEmitted = async (id_doc, body) => await http_client(`/api/v1/documentos-emitir/eliminar/${id_doc}`, body, 'delete')

// accionistas
const getAccionistas = async body => await http_client(`/api/v1/accionistas`, body)
const postAccionista = async (token, body) => await http_client(`/api/v1/accionistas/crear/${token}`, body, 'post')
const deleteAccionista = async (id_acc, body) => await http_client(`/api/v1/accionistas/eliminar/${id_acc}`, body, 'delete')

// establecimientos
const postEstablecimiento = async body => await http_client(`/api/v1/establecimientos/crear`, body, 'post')
const deleteEstablecimiento = async (id_est, body) => await http_client(`/api/v1/establecimientos/eliminar/${id_est}`, body, 'delete')

// pruebas
const getPruebas = async (params = {}) => await http_client(`/api/v1/factura/pruebas`, params, 'get')
const enviarPruebas = async (params = {}) => await http_client(`/api/v1/factura/enviar-pruebas`, params, 'get')

export default {
  aprobarSolicitud,
  aprobarSolicitudDocumento,
  deleteAccionista,
  deleteDocEmitted,
  deleteEstablecimiento,
  enviarPruebas,
  enviarSolicitud,
  getAccionistas,
  getDetailUser,
  getFile,
  getObservaciones,
  getPruebas,
  getSolicitud,
  getSolicitudByNit,
  getSolicitudes,
  observarSolicitud,
  observarSolicitudDocumento,
  postAccionista,
  postConexion,
  postDocEmitted,
  postEstablecimiento,
  postNewPersnJur,
  postNewPersnNat,
  postPersonaJuridica,
  postPersonaNatural,
  postUpdateSolic,
  rechazarSolicitud,
  rechazarSolicitudDocumento,
  storeSolicitud,
  storeSolicitudArchivos,
  updateDetailUser,
  updatePersonaJuridica,
  updatePersonaNatural,
}

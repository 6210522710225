<template>
  <div class="ui-snackbar-container">
    <div :class="`ui-snackbar ${color}`" transition="ui-snackbar-toggle">
      <div class="ui-snackbar-text text-center mr-3">
        <ol v-if="alert?.array_message">
          <li v-for="(item, index) in alert.array_message" :key="index" class="text-start">
            {{ item }}
          </li>
        </ol>
        <span v-else>
          {{ alert.message }}
        </span>
      </div>
    </div>
    <v-btn icon small @click="close" dark class="btn-close">
      <v-icon> mdi-close </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  data: () => ({
    hide: false
  }),

  computed: {
    ...mapState({
      alert: state => state?.utils?.alert
    }),

    color () {
      if (this.alert.type === 'error') {
        return 'error'
      } else if (this.alert.type === 'warning') {
        return 'warning'
      } else if (this.alert.type === 'info') {
        return 'info'
      } else if (this.alert.type === 'success') {
        return 'success'
      } else {
        return 'none'
      }
    }
  },

  methods: {
    ...mapMutations('utils', ['setAlert']),

    timer () {
      let time = this.alert.timeout || 2500
      setTimeout(() => {
        this.close()
      }, time)
    },

    close () {
      this.hide = true
      this.setAlert({ show: false })
    }
  },

  watch: {
    'alert.show' (value) {
      if (value) {
        this.timer()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-snackbar-container {
  position: fixed;
  overflow: hidden;

  top: 6px;
  right: 6px;
  z-index: 10;
  max-width: calc(100% - 12px);
}

.ui-snackbar {
  display: inline-flex;
  align-items: center;

  min-width: auto;
  max-width: 568px;
  min-height: 48px;

  padding: 14px 24px;
  margin: 4px 4px 8px 4px;

  border-radius: 6px;
}

.ui-snackbar-text {
  font-size: 14px;
  color: white;
}

.ui-snackbar-toggle-transition {
  transition: transform 0.3s ease;

  .ui-snackbar-text,
  .ui-snackbar-action {
    opacity: 1;
    transition: opacity 0.3s ease;
  }
}

.ui-snackbar-toggle-enter,
.ui-snackbar-toggle-leave {
  transform: translateY(60px);

  .ui-snackbar-text,
  .ui-snackbar-action {
    opacity: 0;
  }
}

.btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>

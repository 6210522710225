<template>
  <v-dialog
    :value="show"
    max-width="500"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :hide-overlay="$vuetify.breakpoint.smAndDown"
  >
    <v-card class="py-12 px-6">
      <v-card-title>
        <span class="mx-auto text-h5 blueDarkMinsal--text">
          Venta en proceso
        </span>
      </v-card-title>
      <v-card-subtitle class="text-center pt-6">
        ¡Este proceso puede demorar! <br />
        Tu velocidad de procesamiento puede ser afectada por tu conexión a
        internet o la calidad de la señal en tu área. Si experimentas retrasos o
        problemas, intenta de nuevo más tarde. <br />
       
       <strong> No cierres la pestaña o el navegador</strong> <br />
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-center pt-8">
            <v-progress-linear width="5" indeterminate color="blueDarkMinsal" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AppModalLoader',

  computed: {
    ...mapState({
      show: state => state?.utils?.modal_loader || false
    })
  }
}
</script>

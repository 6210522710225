export default [
  {
    path: "administrador",
    name: "administrador",
    component: () => import(/* webpackChunkName: "admin" */ "./admin.vue"),
  },
  {
    path: "detalles-empresa/:id",
    name: "detallesEmpresa",
    component: () =>
      import(/* webpackChunkName: "detallesEmpresa" */ "./detallesEmpresa.vue"),
  },
];

import { http_client } from "@/plugins/http_client";

const url = "/api/v1/catalogos/pais?valor=el salvador";
const getPaises = async (params = {}) => await http_client(url, params, "get");
const getAll =  async (params = {}) => await http_client('/api/v1/catalogos/pais', params, "get");

export default {
  getPaises,
  getAll
};

import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";

import Vue from "vue";
import vuetify from "./vuetify";

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});

<template>
  <v-input :error-messages="errorMessages" maxlength="500">
    <tiptap-vuetify
      :class="`text-tr ${errorMessages.length > 0 ? 'error-tr' : ''}`"
      :extensions="extensions"
      :placeholder="placeholder"
      :value="value"
      @input="handleContentChange"
      @blur="$emit('blur')"
    />
  </v-input>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  name: "AppRichTextComponent",

  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },

  components: { TiptapVuetify },

  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],

    content: "",
  }),

  methods: {
    handleContentChange(value) {
      let content = value;
      if (value == "<p></p>") content = null;
      this.$emit("input", content);
    },
  },
};
</script>

<style lang="scss" scoped>
.ProseMirror {
  min-height: fit-content;
}

.text-tr {
  width: 100%;
  border-radius: 4px;
  border: 2px solid #1c1e4d;
}

.error-tr {
  border: 2px solid #ff5252;
}
</style>

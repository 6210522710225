import defaultState from "./state";

const toFixedNumber = (value) => {
  return value === 0 ? "0" : value.toFixed(2);
};

export const SetTipoFactSelect = (state, payload) => {
  state.tipo_fact_select = payload;
};

export const SetTotalesFinal = (state, payload) => {
  state.totales_final = payload;
};

export const ResetStateMt = (state) => {
  Object.assign(state, defaultState());
};

export const SetListDocsRelacionados = (state, payload) => {
  state.list_docs_relacionados.push(payload);
};

export const RemoveAllDocsRelacionados = (state) => {
  state.list_docs_relacionados = [];
};

export const RemoveListDocsRelacionados = (state, payload) => {
  state.list_docs_relacionados.splice(payload, 1);
};

export const SetListProductos = (state, payload) => {
  state.list_productos.push(payload);
};

export const SetListTributos = (state, payload) => {
  state.list_tributos.push(payload);
};

export const LoadListTributos = (state, payload) => {
  state.list_tributos = payload;
};
// remueve el producto y sus tributos
export const RemoveListTributosByProducto = (state, payload) => {
  state.list_productos = state.list_productos.filter(
    (item) => item?.id_producto !== payload
  );

  state.list_tributos = state.list_tributos.filter(
    (item) => item?.producto_id !== payload
  );
};

export const EliminarDonacionPorDescripcion = (state, payload) => {
  state.list_productos = state.list_productos.filter(
    (objeto) => objeto.descripcion !== payload
  );
};

export const removeItemList = (state, payload) => {
  state.list_productos = state.list_productos.filter(
    (item) => item?.id_producto !== payload
  );
};
export const clearListTributos = (state) => {
  state.list_tributos = [];
};

export const addOrUpdateListProductos = (state, payload) => {
  const producto = state.list_productos.find(
    (item) => item?.movimiento_id === payload.movimiento_id
  );

  if (producto) {
    const subTotal = Number(producto.subTotal) + Number(payload.subTotal);
    producto.cantidad += payload.cantidad;
    producto.subTotal = toFixedNumber(subTotal);
  } else {
    state.list_productos.push(payload);
  }
};

export const addOrUpdateListRetencion = (state, payload) => {
  state.list_productos.push(payload);
};

export const RemoveListProductos = (state, payload) => {
  state.list_productos.splice(payload, 1);
};

export const SetValidFormCliente = (state, payload) => {
  state.valid_form_cliente = payload;
};

export const SetShowModalPago = (state, payload) => {
  state.show_modal_pago = payload;
};

export const SetShowDocsRelacionados = (state, payload) => {
  state.show_docs_relacionados = payload;
};

export const SetDataCliente = (state, payload) => {
  state.data_cliente = payload;
};

export const SetPuntoVenta = (state, payload) => {
  state.punto_venta = payload;
};

export const SetCtrFactura = (state, payload) => {
  state.ctr_factura = payload;
};

export const SetGranContribuyente = (state, payload) => {
  state.gran_contribuyente = payload;
};

export const SetTipoContribuyenteSelected = (state, payload) => {
  state.tipo_contribuyente_selected = payload;
};

export const SetTotalFact = (state, payload) => {
  state.total_fact = payload;
};

export const RemoveAllProductos = (state) => {
  state.list_productos = [];
  state.list_tributos = [];
};

export const RemoveProductosByNumDoc = (state, payload) => {
  state.list_productos = state.list_productos.filter(
    (item) => item?.numero_documento !== payload
  );
};

export const SetValidFormDocLiquid = (state, payload) => {
  state.valid_form_doc_liquid = payload;
};

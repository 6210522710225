<template>
  <v-navigation-drawer
    :class="$vuetify.breakpoint.smAndUp ? 'rounded-r-xl' : ''"
    color="darkBlue"
    dark
    fixed
    app
    :width="$vuetify.breakpoint.smAndUp ? '325' : '100vw'"
    v-model="drawer"
  >
    <template v-slot:prepend>
      <div>
        <v-list-item two-line>
          <v-btn text icon x-large @click="setSideBar(false)">
            <v-icon size="30">mdi-menu</v-icon>
          </v-btn>
        </v-list-item>
        <!--<div class="px-4 mb-4">-->
          <div class="d-flex justify-center align-center">
          <!--<img
            :src="require(`@/assets/img/MAG_logo_white.svg`)"
            width="100%"
            height="auto"
          />-->

          <h1 class="white--text text-h6 text-sm-h5 text-md-h4 font-weight-bold text-wrap">
          CDA
        </h1>
        </div>
      </div>
    </template>

    <v-list dense nav>
      <v-list-item
        v-for="item in menu"
        :key="item.id"
        @click="CloseSideBarMobileFtn(item.uri)"
        link
        active-class=""
      >
        <v-tooltip right color="lightBlue">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <font-awesome-icon :icon="item.icono" />
            </v-list-item-icon>
          </template>
          <span>{{ item.nombre }}</span>
        </v-tooltip>

        <v-list-item-title>
          {{ item.nombre }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import HeaderComponent from "./HeaderComponent.vue";

export default {
  components: { HeaderComponent },
  name: "AppAsideBar",
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    ...mapMutations("utils", ["setSideBar"]),
    CloseSideBarMobileFtn(item) {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.setSideBar(false);
      }
      if (this.$router.currentRoute.path !== item) {
        this.$router.push(item);
      }
    },
      // Acción para detectar si el dispositivo es móvil
      detectMobile() {
          const isMobile = this.$vuetify.breakpoint.xsOnly;
          this.setSideBar(!isMobile); // Si es móvil, ocultar el sideBar
      },
  },
  computed: {
    ...mapState("utils", ["sideBar"]),
    drawer: {
      get() {
        return this.sideBar;
      },
      set(value) {
        this.setSideBar(value);
      },
    },
  },
    created() {
        this.detectMobile(); // Verificamos si es mobile para cerrar el sideBar
    },
};
</script>

<style scoped></style>

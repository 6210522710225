<template>
  <v-row class="pa-4">
    <v-col cols="12" sm="6" md="4" class="pl-sm-12">
      <v-autocomplete
        class="input-field"
        dense
        outlined
        label="Filtrar por"
        color="darkBlue"
        item-value="id"
        item-text="nombre_columna_user"
        hide-details
        v-model="filterSelected"
        :items="ctl_filters"
        return-object
        :prepend-icon="filterSelected ? 'mdi-close-circle-outline' : null"
        @click:prepend="ClearFn"
      />
    </v-col>

    <template v-if="filterSelected">
      <v-col cols="12" sm="5" md="3" v-if="isDate">
        <AppDateInput
          v-model="desde"
          label="Fecha inicial"
          :max="moment().format('YYYY-MM-DD')"
          searchIcon
          @search="SearchFtn"
        />
      </v-col>
      <v-col
        v-else
        cols="12"
        sm="6"
        md="7"
        class="d-flex flex-row align-center"
      >
        <v-text-field
          class="input-field pr-2"
          outlined
          dense
          label="Buscar"
          color="darkBlue"
          hide-details
          v-model="search"
          @keyup.enter="SearchFtn"
          :append-outer-icon="search ? 'mdi-magnify' : null"
          @click:append-outer="SearchFtn"
          maxlength="100"
        />
      </v-col>
    </template>
  </v-row>
</template>
  
  <script>
import AppDateInput from "./AppDateInput.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "AppSearchBarComponent",
  components: {
    AppDateInput,
  },
  props: {
    //TODO: cambiar logica para cambiar textfield y datepicker
    isDate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search_timeout: null,
    filterSelected: null,
    desde: null,
    search: null,
    ctl_filters: [],
  }),
  computed: {
    ...mapState("utils", ["rutas"]),
  },
  methods: {
    ...mapMutations("utils", ["setFilters"]),
    async FetchCtlSearcherFn() {
      const route = this.rutas.find((item) => item.uri == this.$route.path);

      if (route == undefined || route == null) {
        throw new Error("No se encontró la ruta");
      }

      const { data, status } = await this.services.paths.getSearchers(route.id);

      if (status == 200) {
        this.ctl_filters = data;
      }
    },

    ClearFn() {
      this.filterSelected = null;
      this.search = null;
      this.desde = null;

      this.setFilters({});
      this.$emit(
        "search",
        { pagina: 1, por_pagina: 10, paginacion: true },
        true
      );
    },

    SearchFtn() {
      if (this.search_timeout) {
        this.temporalAlert({
          message: "Debes esperar 5 segundos para realizar otra búsqueda",
          show: true,
          type: "info",
        });
      } else {
        this.search_timeout = true;
        const payload = {};
        if (this.filterSelected)
          payload.valor_busqueda = this.isDate ? this.desde : this.search;
        payload.busqueda_id = this.filterSelected?.id;
        this.setFilters(payload);
        this.$emit(
          "search",
          { pagina: 1, por_pagina: 10, paginacion: true },
          true
        );

        setTimeout(() => {
          this.search_timeout = false;
        }, 5000);
      }
    },
  },
  mounted() {
    this.FetchCtlSearcherFn();
  },
  watch: {},

  beforeDestroy() {
    this.setFilters({});
  },
};
</script>
  
  <style lang="scss" scoped>
::v-deep {
  .v-text-field {
    .v-input__control {
      border-radius: 10px;
    }
  }
}
</style>
  
  
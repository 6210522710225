import Vue from "vue";
import VueRouter from "vue-router";
import rolesRouter from "../views/roles/rolesRouter";
import profilesRouter from "../views/profiles/profilesRouter";
import usersRouter from "../views/users/userRouter";
import pathsRouter from "../views/paths/pathRouter";
import adminRouter from "../views/admin/adminRouter";
import catalogosRouter from "../modules/catalogos/catalogosRouter";

import solicitudRouter from "../modules/solicitud/router/solicitudRouter";
import facturacionRouter from "../modules/facturacion/router/facturacionRouter";
import empresaRouter from "../modules/empresa/empresa.router";
import inventarioRouter from "../modules/inventario/inventario.router"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "layout" */ "../views/layout"),
    meta: { requiresAuth: true },
    children: [
      // {
      //   path: "/",
      //   name: "dashboard",
      //   component: () =>
      //     import(/* webpackChunkName: "dashboard" */ "../views/dashboard"),
      // },
      {
        path: "profile",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profiles" */ "../views/profile"),
      },
      ...rolesRouter,
      ...profilesRouter,
      ...usersRouter,
      ...pathsRouter,
      ...adminRouter,
      ...catalogosRouter,
      ...solicitudRouter,
      ...facturacionRouter,
      ...empresaRouter,
      ...inventarioRouter,
      {
        path: "/security",
        name: "security",
        component: () =>
          import(/* webpackChunkName: "qr" */ "../views/auth/security"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/login"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "recuperarPassword" */ "../views/auth/recoverPassword"
      ),
  },
  {
    path: "/reset-password/:id",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "../views/auth/resetPassword"
      ),
  },
  {
    path: "/verify-mail/:token",
    name: "verifyMail",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/verifyMail"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/forbidden"),
  },
  {
    path: "/autenticacionqr",
    name: "2fa",
    component: () => import(/* webpackChunkName: "qr" */ "../views/auth/2fa"),
  },
  // {
  //   path: "/solicitud/create",
  //   name: "solicitudCreate",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "reportesCreate" */ "../modules/solicitud/views/mainFormCreate.vue"
  //     ),
  // },
  // {
  //   path: "/solicitud/update",
  //   name: "solicitudUpdate",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "reportesCreate" */ "../modules/solicitud/views/mainFormEdit.vue"
  //     ),
  // },
  {
    path: "/welcome",
    name: "welcome",
    component: () =>
      import(/* webpackChunkName: "welcome" */ "../views/welcome"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");
  const required = to.matched.some((record) => record.meta.requiresAuth);

  if (required && !token) {
    next("/login");
    return;
  }
  // else if (to.fullPath === "/login" && token) {
  //   next("/");
  //   return;
  // }
  if (await Vue.prototype.canNext(to)) {
    next();
  } else {
    token ? next("/forbidden") : next("login");
  }
});

export default router;

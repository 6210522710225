const defaultState = () => ({
  tipo_fact_select: {},
  data_cliente: {},
  punto_venta: {},
  total_fact: 0,

  // datos de tablas
  list_docs_relacionados: [],
  list_productos: [],
  list_tributos: [],
  totales_final: [],
  gran_contribuyente: false,
  tipo_contribuyente_selected: null,

  // validacion de formularios
  valid_form_cliente: false,
  valid_form_doc_liquid: false,
  ctr_factura: false,

  // modales
  show_modal_pago: false,
  show_docs_relacionados: false,

  // functions
  inject_results: null,

  HEADER_TABLE: [
    {
      text: "Código",
      value: "codigo",
      sortable: false,
      ids_permitidos: [1, 2, 3, 4, 5, 9, 10, 11],
    },
    {
      text: "Cantidad",
      value: "cantidad",
      sortable: false,
      ids_permitidos: [1, 2, 3, 4, 5, 9, 10, 11],
    },
    {
      text: "Descripción",
      value: "descripcion",
      sortable: false,
      ids_permitidos: [1, 2, 3, 4, 5, 9, 10, 11],
    },
    {
      text: "Unidad M",
      value: "unidad_medida.nombre",
      sortable: false,
      ids_permitidos: [1, 2, 3, 4, 5, 9, 10, 11],
    },

    {
      text: "Precio U.",
      value: "precio_unitario",
      sortable: false,
      ids_permitidos: [1, 2, 3, 4, 5, 9, 10],
    },
    {
      text: "Sub-Total",
      value: "subTotal",
      sortable: false,
      ids_permitidos: [1],
    },

    {
      text: "Valor unitario",
      value: "valorU",
      sortable: false,
      ids_permitidos: [11],
    },

    {
      text: "Acción",
      value: "actions",
      ids_permitidos: [1, 2, 3, 4, 5, 6, 7, 9, 10, 11],
      sortable: false,
    },
  ],

  headers_total: [


    {
      id: 10,
      text: "TOTAL",
      key: "total",
      valor: 0,
      ids_permitidos: [1, 2, 3, 4, 5, 7],
    },
  ],

  HEADER_TABLE_V: [
    {
      text: "Código",
      value: "producto_id.codigo",
      ids_permitidos: [1, 2, 3, 4, 5, 9, 10],
    },
    {
      text: "Código",
      value: "id",
      ids_permitidos: [11],
    },
    {
      text: "Cantidad",
      value: "cantidad",
      ids_permitidos: [1, 2, 3, 4, 5, 9, 10, 11],
    },
    {
      text: "Descripción",
      value: "descripcion",
      ids_permitidos: [1, 2, 3, 4, 5],
    },
    {
      text: "Descripción",
      value: "producto_id.descripcion",
      ids_permitidos: [9],
    },
    {
      text: "Unidad M",
      value: "producto_id.unidad_medida.valor",
      ids_permitidos: [1, 2, 3, 4, 5, 9, 10],
    },
    {
      text: "Unidad M",
      value: "tipo_unidad.valor",
      ids_permitidos: [11],
    },
    {
      text: "Otros montos",
      value: "otros",
      ids_permitidos: [], //1, 2, 3, 4, 5, 9
    },
    {
      text: "Precio U. ($)",
      value: "precio_unitario",
      ids_permitidos: [1, 2, 3, 4, 5, 9, 10],
    },
    {
      text: "Desc. ($)",
      value: "descuento",
      ids_permitidos: [1, 2, 3, 4, 5, 9, 10],
    },
    {
      text: "N°",
      value: "numero",
      ids_permitidos: [6, 7],
    },
    {
      text: "Tipo de documento",
      value: "tipo_dte.valor",
      ids_permitidos: [7],
    },
    {
      text: "N° de documento relacionado",
      value: "numero_documento",
      ids_permitidos: [7],
    },
    {
      text: "Fecha",
      value: "fecha_generacion",
      ids_permitidos: [7],
    },
    {
      text: "Observación",
      value: "observacion",
      ids_permitidos: [7],
    },
    {
      text: "Exportaciones",
      value: "exportaciones",
      ids_permitidos: [7],
    },
    {
      text: "V. no sujetas ($)",
      value: "venta_no_sujeta",
      ids_permitidos: [1, 2, 3, 4, 5, 7],
    },
    {
      text: "V. Exentas ($)",
      value: "venta_exenta",
      ids_permitidos: [1, 2, 3, 4, 5, 7],
    },

    {
      text: "V. No Gravadas ($)",
      value: "no_gravado",
      ids_permitidos: [9],
    },
    {
      text: "V. Gravadas ($)",
      value: "venta_gravada",
      ids_permitidos: [1, 2, 3, 4, 5, 7, 9],
    },

    {
      text: "Ventas",
      value: "compra",
      ids_permitidos: [10],
    },

    {
      text: "Tipo de Doc. Relacionado",
      value: "tipo_dte_id.valor",
      ids_permitidos: [6],
    },
    {
      text: "Correlativo de Doc. Relacionado",
      value: "numero_control",
      ids_permitidos: [6],
    },
    {
      text: "Fecha del Doc.",
      value: "fecha_emision",
      ids_permitidos: [6],
    },
    {
      text: "Descripción",
      value: "descripcion",
      ids_permitidos: [6, 10, 11],
    },
    {
      text: "Monto sujeto o retención",
      value: "monto_sujeto_retencion",
      ids_permitidos: [6],
    },
    {
      text: "IVA Retenido",
      value: "iva_retenido",
      ids_permitidos: [6],
    },
    {
      text: "Valor unitario",
      value: "valor_unitario",
      ids_permitidos: [11],
    },
    {
      text: "Depreciación",
      value: "depreciacion",
      ids_permitidos: [11],
    },
    {
      text: "Valor donado",
      value: "valor_donado",
      ids_permitidos: [11],
    },
  ],
});

export default defaultState;

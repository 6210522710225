<template>
  <section>
    <v-card class="rounded-xl">
      <v-data-table
        :item-class="() => 'v-data-table-row'"
        :headers="headers"
        :items-per-page="per_page"
        :items="items"
        hide-default-footer
        :loading="loading"
      >
        <!-- slots -->
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" v-if="name"></slot>
        </template>
      </v-data-table>

      <v-card-text>
        <div class="d-flex flex-column flex-md-row justify-center align-center">
          <div class="d-flex flex-row mx-2">
            <span class="pt-1 pr-2" v-text="'Filtrar por página:'" />
            <div class="select_pag">
              <v-select
                :disabled="totalRows == 0"
                class="custom-select"
                hide-details
                dense
                :items="ctlPerPage"
                color="blueDarkMinsal"
                item-color="primary"
                append-icon="mdi-chevron-down"
                v-model="per_page"
              />
            </div>
          </div>

          <div class="mx-2 d-flex flex-column flex-md-row align-center">
            <v-btn
              text
              class="text-no-style"
              :disabled="page == 1 || totalRows == 0"
              @click="page--"
            >
              <span v-text="'Anterior'" />
            </v-btn>

            <v-pagination
              class="px-1"
              :style="{ scale: breakPoint(290) ? 0.85 : 1 }"
              :total-visible="totalVisible"
              color="blueDarkMinsal"
              v-model="page"
              :length="pagesCmp ?? 0"
            />

            <v-btn
              text
              class="text-no-style"
              :disabled="page == pagesCmp || totalRows == 0"
              @click="page++"
            >
              <span v-text="'Siguiente'" />
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "AppTableComponent",

  props: {
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },

    ctlPerPage: {
      type: Array,
      default: () => [5, 10, 25, 100],
    },

    totalRows: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    page: 1,
    per_page: 10,
  }),

  computed: {
    pagesCmp() {
      return Math.ceil(this.totalRows / this.per_page);
    },

    totalVisible() {
      const screens = [
        { device: this.$vuetify.breakpoint.smAndUp, visible: 10 },
        { device: this.$vuetify.breakpoint.xsOnly, visible: 5 },
      ];
      const screen = screens.find((screen) => screen.device);
      return screen.visible;
    },
  },

  methods: {
    breakPoint(width) {
      const widthScreen = this.$vuetify.breakpoint.width;
      return widthScreen <= width;
    },
    getRowClass(index) {
      return index % 2 === 0 ? "rowGray" : "rowWhite";
    },

    refresh() {
      this.$emit("refresh", {
        pagina: this.page,
        por_pagina: this.per_page,
        paginacion: "true",
      });
    },

    reset() {
      this.page = 1;
      this.per_page = 10;
    },
  },
  watch: {
    page(value) {
      this.$emit("refresh", {
        pagina: value,
        por_pagina: this.per_page,
        paginacion: "true",
      });
    },
    per_page(value) {
      this.page = 1;
      this.$emit("refresh", {
        pagina: this.page,
        por_pagina: value,
        paginacion: "true",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-pagination__item {
  border-radius: 10px !important;
}

.custom-select {
  max-width: min-content;
}

::v-deep .v-data-table-header th {
  color: #000 !important;
}
::v-deep .v-data-table-row {
  &:nth-child(even) {
    background-color: #ffffff; // Fondo blanco para filas pares
  }

  &:nth-child(odd) {
    background-color: #e0e0e0; // Fondo gris para filas impares
  }

  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 15px;
      }
      &:last-child {
        border-bottom-right-radius: 15px;
      }
    }
  }
}

::v-deep .v-data-table-row:hover {
  background-color: #bdbdbd !important; // Fondo rojo para filas al pasar el ratón
  color: #fff;

  &:first-child {
    td {
      &:first-child {
        border-top-left-radius: 0px !important;
      }
      &:last-child {
        border-top-right-radius: 0px !important;
      }
    }
  }
  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 15px !important;
      }
      &:last-child {
        border-bottom-right-radius: 15px !important;
      }
    }
  }
}
</style>

import { http_client } from "@/plugins/http_client";

const url = "/api/v1/usuario";
const get = async (params = {}) => await http_client(url, params, "get");
const getRoles = async (params = {}) =>
  await http_client(`${url}/listar-roles`, params, "get");
const store = async (body) => await http_client(`${url}/create`, body, "post");
const getById = async (id) => await http_client(`${url}/ver/${id}`);
const update = async (id, body) =>
  await http_client(`${url}/actualizar/${id}`, body, "put");
const eliminar = async (id, body) =>
  await http_client(`${url}/eliminar/${id}`, body, "put");

export default {
  get,
  getRoles,
  store,
  getById,
  update,
  eliminar,
};

import { http_client } from "@/plugins/http_client";

const url_ecuacion = "/api/v1/ecuaciones";
const get = async (params = {}) => await http_client(url_ecuacion, params);
const create = async (params = {}) => await http_client(`${url_ecuacion}/crear`, params, "post");
const update = async (id_tribute, params = {}) => await http_client(`${url_ecuacion}/actualizar/${id_tribute}`, params, "put");

export default {
    get,
    create,
    update,
};
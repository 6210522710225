import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "../assets/scss/index.scss";
import es from "vuetify/lib/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es",
  },
  theme: {
    themes: {
      //Se definen colores globales para el tema claro
      light: {
        blueMinsal: "#175EFB",
        blueGrayMinsal: "#6A83BE",
        blueDarkMinsal: "#1C1E4D",
        darkBlue: "#1C1E4D",
        boldBasic: "#000",

        bgWhite: "#FFFFFF",
        primary: "#175EFB",
        lightGreen: "#9AEBA3",
        lightRed: "#F25757",
        lightGray: "#B8B8B8",
        darkYellow: "#F3A723",
        lightYellow: "#FAEEB9",
        yellowBrown: "#CDA41D",
        darkGray: "#697182",
        grayMinsal: "#8D8E90",
        blueGray: "#333A45",
        lightBlue: "#30A4F1",
        lightGraySecond: "#EDEDF1",
        rowGray: "#EDEDF1",
        rowWhite: "#FFFFFF",
        blackGray: "#5A6268",
        blueGreen: "#138496",
      },
      dark: {
        bgMinsal: "#2f3032",
        blueMinsal: "#175EFB",
        blueGrayMinsal: "#6A83BE",
        blueDarkMinsal: "#2D52A8",
        primary: "#175EFB",
        grayMinsal: "#8D8E90",
        // primary: {
        //     base: colors.purple.base,
        // }
      },
    },
  },
});

import Vue from "vue";
import auth from "./auth.services";
import users from "./user.services";
import roles from "./roles.services";
import profiles from "./profiles.services";
import typeRoles from "./typeRoles.services";
import paths from "./path.services";
import catalogosServices from "../modules/catalogos/services/index";
import factura from "./factura.services"
import ecuaciones from "./ecuaciones.services";
//SHARED
import municipios from "../modules/shared/services/municipios.services";
import departamentos from "../modules/shared/services/departamentos.services";
import paises from "../modules/shared/services/paises.services";
import actividadesEconomicas from "../modules/shared/services/actividadEconomica.services";
import tiposDocumentos from "../modules/shared/services/tiposDocumentos.services";
import tiposEstablecimientos from "../modules/shared/services/tiposEstablecimientos.services";
import tipoPersona from "../modules/shared/services/tipoPersona.services";
//MODULES
import solicitudServices from "../modules/solicitud/services/solicitud.services";
import empresasServices from "./empresas.services";
import facturacionServices from "../modules/facturacion/services/facturacion.services";
import inventarioServices from "./inventario.services";

Vue.prototype.services = {
  auth,
  users,
  roles,
  profiles,
  typeRoles,
  paths,
  catalogosServices,

  paises,
  departamentos,
  municipios,
  actividadesEconomicas,
  tiposDocumentos,
  tiposEstablecimientos,
  tipoPersona,

  solicitudServices,
  empresasServices,
  facturacionServices,
  factura,
  ecuaciones,
  inventarioServices
};

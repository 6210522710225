import { http_client } from "@/plugins/http_client";

const url = "/api/v1/catalogos/tipo-persona";
const get = async (params = {}) => await http_client(url, params, "get");
const getById = async (id) => await http_client(`${url}/${id}`);

export default {
  get,
  getById,
};

<template>
  <div class="text-center">
    <h1
      class="darkBlue--text text-h6 text-sm-h5 text-md-h4 font-weight-bold text-wrap"
    >
      {{ headerTitle }}
    </h1>
  </div>
</template>

<script>
export default {
  name: "HaderComponent",
  props: {
    headerTitle: {
      type: String,
      default: "Header",
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>

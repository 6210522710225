import { http_client } from "@/plugins/http_client";

const url = "/api/v1/catalogos/tributo";
const get = async (params = {}) => await http_client(url, params, "get");
const store = async (body) => await http_client(`${url}/crear`, body, "post");
const getById = async (id) => await http_client(`${url}/${id}`);
const update = async (id, body) =>
  await http_client(`${url}/${id}`, body, "put");
const getTypeTributes = async (params = {}) => await http_client('/api/v1/catalogos/tipo-tributo', params, "get");

export default {
  get,
  store,
  getById,
  update,
  getTypeTributes
};

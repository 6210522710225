export default [
  {
    path: "/inventario/list",
    name: "listInventarios",
    component: () =>
      import(
          /* webpackChunkName: "listInventarioView" */ "./views/listInventarioView.vue"
      ),
  },
  {
    path: "/inventario/crear",
    name: "createInventario",
    component: () =>
      import(
          /* webpackChunkName: "createInventarioView" */ "./views/createInventarioView.vue"
      ),
  },
  {
    path: "/inventario/detalle/:id",
    name: "detalleInventario",
    component: () =>
      import(
          /* webpackChunkName: "detalleInventarioView" */ "./views/detalleInventarioView.vue"
      ),
  },
  {
    path: "/inventario/cierre/:id",
    name: "cierreInventario",
    component: () =>
      import(
          /* webpackChunkName: "cierreInventarioView" */ "./views/cierreInventarioView.vue"
      ),
  },
  {
    path: "/inventario/cierre/detalle/:id",
    name: "detCierreInventario",
    component: () =>
      import(
          /* webpackChunkName: "detCierreInventarioView" */ "./views/cierreInventarioView.vue"
      ),
  },
];
import defaultState from "./state";
import * as mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
    
}

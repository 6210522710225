import { http_client } from "@/plugins/http_client";

const url = "/api/v1/descuentos";
const get = async (params = {}) => await http_client(`${url}/obtener-descuentos`, params, "get");
// const getTemplate = async (params = {}) =>
//   await http_client(`${url}/descargar-plantilla`, params, "get");
// const getFiltro = async (params = {}) =>
//   await http_client(`${url}/verSimplificado`, params, "get");
const store = async (body) => await http_client(`${url}/agregar-nuevo-descuento`, body, "post");
// const load = async (body) =>
//   await http_client(`${url}/cargar-productos`, body, "post");
// const getById = async (id) => await http_client(`${url}/ver/${id}`);
const update = async (id, body) =>
  await http_client(`${url}/editar-descuento/${id}`, body, "put");
// const eliminar = async (id, body) =>
//   await http_client(`${url}/eliminar/${id}`, body, "delete");

export default {
    store,
    get,
    update
};
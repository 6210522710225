import Vue from "vue";
import store from "../store";
import moment from "moment";

Vue.prototype.showLoader = async () => {
  await store.commit("utils/setLoader", true);
};

Vue.prototype.hideLoader = async () => {
  await store.commit("utils/setLoader", false);
};

Vue.prototype.haveRole = (role) => {
  return store?.state?.userInfo?.user?.roles.some(
    (item) => item.nombre === role
  );
};

Vue.prototype.haveRoles = (roles) => {
  return store?.state?.userInfo?.user?.roles.some((item) =>
    roles.includes(item.nombre)
  );
};

Vue.prototype.temporalAlert = async (alert) => {
  await store.commit("utils/setAlert", alert);
};

Vue.prototype.isDui = (dui) => {
  if (dui === null) return false;
  let valido = false;

  // Validando la longitud del numero de DUI
  if (dui.length === 10) {
    // Validando que no sea ceros todos los dígitos
    if (dui !== "00000000-0") {
      // Obteniendo los dígitos y el verificador
      let [digitos, validador] = dui.split("-");

      // Verficiando que la cadena
      if (typeof digitos !== "undefined" && typeof validador !== "undefined") {
        // Verificando que el validador sea de un solo caracter
        if (validador.length === 1) {
          // Convirtiendo los digitos a array
          digitos = digitos.split("");

          // Convirtiendo los datos a tipo integer
          validador = parseInt(validador, 10);
          digitos = digitos.map((digito) => parseInt(digito, 10));

          // Obteniendo la suma corresponiente
          let suma = digitos.reduce(
            (total, digito, index) => (total += digito * (9 - index)),
            0
          );

          // Obteniendo el Modulo base 10
          let mod = suma % 10;
          mod = validador === 0 && mod === 0 ? 10 : mod;

          let resta = 10 - mod;

          if (resta === validador) {
            valido = true;
          }
        }
      }
    }
  }
  return valido;
};

Vue.prototype.isNit = (nit) => {
  if (nit === null) return false;

  let valido = false;

  // Validando la longitud del numero de DUI
  if (nit.length === 17) {
    // Validando que no sea ceros todos los dígitos
    if (
      nit !== "0000-000000-000-0" &&
      nit.match(/^[0-9]{4}-[0-9]{6}-[0-9]{3}-[0-9]$/)
    ) {
      // Obteniendo los dígitos y el verificador por separado
      let [ubicacion, fecha, correlativo, validador] = nit.split("-");
      let digitos = (ubicacion + fecha + correlativo).split("");

      // Convirtiendo los datos a tipo integer
      validador = parseInt(validador, 10);
      correlativo = parseInt(correlativo, 10);
      let suma = 0;
      let mod = 0;
      if (correlativo <= 100) {
        // Obteniendo la suma corresponiente
        suma = digitos.reduce(
          (total, digito, index) =>
            (total += parseInt(digito, 10) * (14 - index)),
          0
        );
        // Obteniendo el Modulo base 11
        mod = suma % 11;
        mod = mod === 10 ? 0 : mod;
      } else {
        // Obteniendo la suma corresponiente
        suma = digitos.reduce(
          (total, digito, index) =>
            (total +=
              parseInt(digito, 10) *
              parseInt(3 + 6 * Math.floor((index + 5) / 6) - (index + 1), 10)),
          0
        );
        // Obteniendo el Modulo base 11
        mod = suma % 11;
        mod = mod > 1 ? 11 - mod : 0;
      }
      if (mod === validador) {
        valido = true;
      }
    }
  }
  return valido;
};

Vue.prototype.isPassport = (passport) => {
  if (passport === null) return false;

  const firstLetter = passport.charAt(0);
  const regexA = /^[A-Z]{1}$/;
  const regexB = /^[A-Z0-9]*$/;

  return regexA.test(firstLetter) && regexB.test(passport.slice(1));
};

Vue.prototype.getPaginationProperties = (response) => {
  return {
    page: Number(response.headers.page),
    per_page: Number(response.headers.per_page),
    total_rows: Number(response.headers.total_rows),
  };
};
//Funcion auxiliar para verificar paths accesibles
const buscarRuta = (rutas, ruta) => {
  return rutas.some((item) => {
    if (item?.childrens?.length > 0) {
      return buscarRuta(item.childrens, ruta);
    }

    return (
      item.nombreUri.toLowerCase() === ruta.name.toLowerCase() ||
      ruta.name === "welcome" ||
      ruta.name === "dashboard"
    );
  });
};

//Funcion para verificar las paths accesibles
Vue.prototype.canNext = async (ruta) => {
  //Rutas a las que el usuario siempre va a poder acceder
  const permit = [
    "Forbidden",
    "security",
    "login",
    "forgot-password",
    "reset-password",
    "2fa",
    "verifyMail",
    // "solicitudCreate",
    // "solicitudUpdate",
    "welcome",
  ];

  if (!ruta.name) return false;

  const permiso = permit.some(
    (row) => row.toLowerCase() === ruta.name.toLowerCase()
  );

  //Si la ruta a ingresar esta definida en el arreglo local, permite ingreso

  if (permiso) return true;

  /*Se verifica si la ruta proporcionada se encuentra en las paths
    almacenadas, si no hay entonces se hace la petición para almacenarlas*/
  let { utils } = store.state;

  if (utils.rutas && utils.rutas.length === 0) {
    await store.dispatch("utils/getMenu");
  }
  //Busca ruta en el arreglo de paths
  return buscarRuta(utils.rutas, ruta);
};

Vue.prototype.FormatDatePrt = (date_param, time_param = false) => {
  if (date_param != null) {
    if (time_param) {
      return moment(date_param).format("DD/MM/YYYY HH:mm a");
    } else {
      return moment(date_param).format("DD/MM/YYYY");
    }
  } else {
    return "";
  }
};

Vue.prototype.FormatNumberPrt = (value) => {
  if (!value) return 0;
  return parseFloat(value.replace(/[^0-9.]/g, ""));
};

Vue.prototype.FormatCurrencyPrt = (value, symbol) => {
  if (value != null) {
    return new Intl.NumberFormat("es-SV", {
      style: symbol ? "currency" : "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: "USD",
    }).format(value);
  } else {
    return "";
  }
};

Vue.prototype.VerifyIdFactPrt = (ids_params) => {
  return ids_params.includes(store.state.facturacion?.tipo_fact_select?.id);
};

Vue.prototype.FormatDocPrt = (document, type) => {
  let mask = "";

  function formatter(doc) {
    let result = "";
    let valIndex = 0;
    for (let maskIndex = 0; maskIndex < mask.length; maskIndex++) {
      if (mask[maskIndex] === "#") {
        result += doc?.charAt(valIndex);
        valIndex++;
      } else {
        result += mask[maskIndex];
      }
    }

    return result;
  }

  switch (type) {
    case "nit":
      mask = "####-######-###-#";

      return formatter(document);

    case "dui":
      mask = "########-#";

      return formatter(document);

    case "tel":
      mask = "####-####";

      return formatter(document);

    case "nrc":
      return document && document.includes("-")
        ? document
        : (document &&
            document.substring(0, document.length - 1) +
              "-" +
              document.substring(document.length - 1)) ||
            "";

    default:
      console.error("No se ha definido el tipo de documento");
      return document;
  }
};

Vue.prototype.ConvertNumberPrt = (number) => {
  let clean_number;

  // identificar si el numero viene como string, si es asi, convertirlo a numero
  if (typeof number === "string") {
    // verificar si es un numero valido
    if (!isNaN(number)) {
      clean_number = parseFloat(number);
    } else {
      throw new Error("El valor ingresado no es un numero valido");
      return 0;
    }
  } else {
    clean_number = number;
  }

  // verificar si tiene decimales, si no tiene retornar el numero
  if (clean_number % 1 === 0) {
    return clean_number;
  } else {
    // convertir a formato de moneda con dos decimales
    const local = new Intl.NumberFormat("es-SV", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: "USD",
    }).format(clean_number);
    // eliminar comas y retornar el numero
    clean_number = parseFloat(local.replace(/,/g, ""));
  }

  return clean_number;
};

import store from "../store";
import Vue from "vue";

const hasRole = (role) => {
  const rolesList = store.state.userInfo.user?.roles.map((item) => item.nombre);
  return rolesList?.some((value) => value.toLowerCase() == role.toLowerCase());
};

Vue.prototype.hasRole = hasRole;

export { hasRole };

<template>
  <v-text-field
    :name="name"
    :value="value"
    @input="HandlerInputFtn"
    @blur="$emit('blur')"
    :label="label"
    :type="show_password ? 'text' : 'password'"
    :error-messages="errorMessages"
    outlined
    :clearable="clearable"
    :readonly="readonly"
    :disabled="disabled"
    :hide-details="hideDetails"
    :dense="dense"
    :color="color"
    :maxlength="maxlength"
  >
    <template #append>
      <v-icon
        v-if="!show_password"
        @click="show_password = !show_password"
        :color="errorMessages.length > 0 ? 'error' : ''"
      >
        mdi-eye
      </v-icon>
      <v-icon
        v-else
        @click="show_password = !show_password"
        :color="errorMessages.length > 0 ? 'error' : ''"
      >
        mdi-eye-off
      </v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "AppPasswordComponent",

  props: {
    name: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    maxlength: {
      type: Number,
      default: 25,
    },
  },

  data: () => ({
    show_password: false,
  }),

  methods: {
    HandlerInputFtn(value) {
      this.$emit("input", value);
    },
  },
};
</script>
<template>
  
  <v-footer class="white py-2" height="75" app absolute inset>
    <br>
    <v-row class="align-center pb-3">
      <v-col cols="12" lg="3" class="d-flex justify-center py-0">
        <p class="darkBlue--text ma-0 pa-0 text-center">
          Central de abastos
        </p>
      </v-col>
      <v-col cols="12" lg="6" class="d-flex justify-center">
        <div class="px-4">
          <img
            style="max-width: 100%"
            :src="require(`@/assets/img/logo-gobierno.svg`)"
          />
        </div>
      </v-col>
      <!-- <v-col cols="12" lg="3" class="d-flex justify-center py-0">
        <p class="darkBlue--text ma-0 pa-0">Políticas de privacidad</p>
      </v-col> -->
    </v-row>
  </v-footer>
</template>

<script>
export default {};
</script>

<style scoped>
</style>
import { http_client } from "@/plugins/http_client";

const url = "/api/v1/catalogos/departamento";
const get = async (params = {}) => await http_client(url, params, "get");
const getById = async (id) => await http_client(`${url}/${id}`);
const getMunicipios = async (params = {}) =>
  await http_client("/api/v1/catalogos/municipio", params, "get");
const getDistritos = async (params = {}) =>
  await http_client("/api/v1/catalogos/distritos", params, "get");

export default {
  get,
  getById,
  getMunicipios,
  getDistritos
};

export default {
  setEstablecimientos(state, payload) {
    state.establecimientos = payload;
  },
  setPaginationData(state, payload) {
    state.filters.page = Number(payload.page);
    state.filters.perPage = Number(payload.per_page);
    state.filters.totalRows = Number(payload.total_rows);
  },

  setCtlDepartamentosMtt(state, payload) {
    state.ctl_departamentos_st = payload;
  },

  setStepsInfoMtt(state, payload) {
    state.step_1_st = payload.step1;
    state.step_2_st = payload.step2;
  },

  setTotalAccCreate(state, payload) {
    state.total_acc_create = payload;
  },

  setTotalAccUpdate(state, payload) {
    state.total_acc_update = payload;
  },

  setRegisterTypeMtt(state, payload) {
    state.register_type = payload;
  },

  setCtlActividadesMtt(state, payload) {
    state.ctl_actividades = payload;
  },

  setCtlTipoDocumentoMtt(state, payload) {
    state.ctl_tipo_documento = payload;
  },

  setOriginMtt(state, payload) {
    state.origin = payload;
  },

};

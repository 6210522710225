<template>
  <v-app>
    <v-main class="pa-0 ma-0 myFont global-container">
      <v-fade-transition>
        <v-snackbar
          v-model="alert().show"
          :value="alert().show"
          v-if="alert().show && !$vuetify.breakpoint.xs"
          :timeout="alert().timeout"
          top
          class="fixed white--text rounded-xxl custom-snackbar"
          z-index="10"
          rounded
          elevation="1"
          :color="alert().type"
          right
        >
          <v-icon left v-if="alert().type === 'success'">
            mdi-check-circle-outline
          </v-icon>
          <v-icon left v-if="alert().type === 'error'">
            mdi-close-circle-outline
          </v-icon>
          <v-icon left v-if="alert().type === 'warning'">
            mdi-alert-circle-outline
          </v-icon>
          <v-icon left v-if="alert().type === 'info'">
            mdi-information-outline
          </v-icon>


          <div v-if="alert()?.array_message" class="pt-2">
            <ol>
              <li v-for="(item, index) in alert().array_message" :key="index">
                {{ item }}
              </li>
            </ol>
          </div>

          <span class="body-1" v-else>
            <strong> {{ alert().message }} </strong>
          </span>

          <template #action="{ attrs }">
            <v-btn v-bind="attrs" icon right @click="alert().show = false">
              <v-icon small> mdi-close </v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <compact-snackbar
          v-else-if="$vuetify.breakpoint.xs"
          v-show="alert().show"
        />
      </v-fade-transition>
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import CompactSnackbar from './components/CompactSnackbar.vue'

export default {
  name: 'App',

  components: {
    CompactSnackbar
  },

  data: () => ({
    drawer: null,
    opciones: [
      {
        title: 'Perfil',
        icon: 'mdi-account'
      },
      {
        title: 'Cerrar Session',
        icon: 'mdi-logout'
      }
    ]
  }),
  methods: {
    ...mapGetters({
      alert: 'utils/getAlert'
    })
  }
}
</script>
<style lang="scss">
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  // background: rgb(var(--v-theme-primary));
  background: #1c1e4d;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:window-inactive {
  // background: rgb(var(--v-theme-primary));
  background: #1c1e4d;
}

// se oculta si la pantalla es muy pequeña
@media (max-width: 250px) {
  .global-container {
    display: none !important;
  }
}

</style>

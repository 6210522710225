import { http_client } from "@/plugins/http_client";

const url = "/api/v1/comprobante";
const getEnviadas = async (params = {}) => await http_client(`${url}/obtener-comprobantes`, params, "get");
const getRecibidas = async (params = {}) => await http_client(`${url}/recibidas`, params, "get");

const getRechazadas = async (params = {}) => await http_client(`${url}/rechazadas`, params, "get");

const getDataCliente = async (body) => await http_client(`/api/v1/cliente/obtenerCliente`, body);

// detalle DTEs
const getDetail = async (id_factura, body) => await http_client(`${url}/ver/${id_factura}`, body);

export default {
  getEnviadas,
  getRecibidas,
  getRechazadas,
  getDetail,
  getDataCliente
};

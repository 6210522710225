export default [
  {
    path: "/solicitud/list",
    name: "solicitudList",
    component: () =>
      import(
        /* webpackChunkName: "solicitudList" */ "../views/solicitudList.vue"
      ),
  },
  {
    path: "/solicitud/show/:nit",
    name: "solicitudShow",
    component: () =>
      import(
        /* webpackChunkName: "solicitudShow" */ "../../empresa/views/empresaGeneralView.vue"
      ),
  },
  {
    path: "/empresa/register",
    name: "solicitudRegister",
    component: () =>
      import(
        /* webpackChunkName: "solicitudRegister" */ "../views/mainFormRegister.vue"
      ),
  },
];

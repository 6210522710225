export default {
  TributosResumen: (state) => {
    //sumar todos los tributos por el id
    const tributos = state.list_tributos.map((item) => ({...item}));

    return tributos.reduce((acc, item) => {
      const found = acc.find((el) => el.id === item.id);
      const value = parseFloat(item.monto_calculado);
      if (found) {
        found.monto_calculado += value;
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
  },
};

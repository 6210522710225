<template>
  <v-menu
    :close-on-content-click="closeOnClick"
    :max-width="maxWidth"
    :min-width="minWidth"
    :offset-y="offsetY"
    :offset-x="offsetX"
    :transition="transition"
    v-model="show"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :error-messages="errorMessages"
        :value="FormatDatePrt(value)"
        @blur="$emit('blur', $event)"
        @click:clear="$emit('click:clear', $event), HandlerInputFtn(null)"
        append-icon="mdi-calendar"
        :clearable="clearable"
        :label="label"
        outlined
        :placeholder="placeholder"
        readonly
        v-bind="attrs"
        v-on="on"
        :color="color"
        :disabled="disabled"
        :hide-details="hideDetails"
        :dense="dense"
        :append-outer-icon="searchIcon && value ? 'mdi-magnify' : null"
        @click:append-outer="SearchFtn"
      />
    </template>
    <v-date-picker
      :max="max"
      no-title
      scrollable
      :value="value"
      @input="HandlerInputFtn"
      :min="min"
    />
  </v-menu>
</template>

<script>
export default {
  name: "AppDateInput",

  props: {
    closeOnClick: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: "290px",
    },
    minWidth: {
      type: String,
      default: "auto",
    },
    offsetY: {
      type: Boolean,
      default: true,
    },
    offsetX: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: "scale-transition",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "blueDarkMinsal",
    },
    max: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    searchIcon: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    show: false,
  }),

  methods: {
    HandlerInputFtn(value) {
      this.$emit("input", value);
      this.show = false;
    },
    SearchFtn() {
      this.$emit("search")
    }
  },
};
</script>

import { http_client } from "@/plugins/http_client";

const url = "/api/v1/catalogos/tipo-item";
const get = async (params = {}) => await http_client(url, params, "get");
const store = async (body) => await http_client(`${url}/crear`, body, "post");
const getById = async (id) => await http_client(`${url}/${id}`);
const update = async (id, body) =>
  await http_client(`${url}/${id}`, body, "put");

const getTiposProducto = async (params = {}) => await http_client(`api/v1/tipo-producto/obtener-tipo-productos`, params, "get");
const crearTipoProducto = async (body = {}) => await http_client(`api/v1/tipo-producto/crear-tipo-producto`, body, "post");
const editarTipoProducto = async (id, params) => await http_client(`api/v1/tipo-producto/editar-tipo-producto/${id}`, params, "put");
export default {
  get,
  store,
  getById,
  update,
  getTiposProducto,
  crearTipoProducto,
  editarTipoProducto
};

export default {
    namespaced: true,
    state: {
        item_selected: {},
        status_modal: false,
    },
    mutations: {
        SetItemSelected(state, payload) {
            state.item_selected = payload
        },
        SetStatusModal(state, payload) {
            state.status_modal = payload
        },
    }
}

import Vue from "vue";
import Vuex from "vuex";
import utils from "../modules/utils";
import facturacion from "../modules/facturacion/store"
import jwtDecode from "jwt-decode";

import solicitudStore from "../modules/solicitud/store";
import tributos from "../modules/catalogos/tributos/store";
import puntoVenta from '../modules/catalogos/puntosVenta/puntoVenta.store'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    userInfo: {},
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
  },
  actions: {
    setAuth({ commit }, payload) {
      const { access_token, refresh_token } = payload;
      commit("setUserInfo", jwtDecode(access_token));
      commit("setToken", access_token);
      localStorage.setItem("token", access_token);
      localStorage.setItem('refresh_token', refresh_token)
    },

    clearAuth({ commit }) {
      commit("setUserInfo", {});
      commit("setToken", null);
      localStorage.clear();
    }
  },
  modules: {
    utils,
    facturacion,
    solicitudStore,
    tributos,
    puntoVenta
  },
});

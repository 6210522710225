<template>
  <section>
    <div>
      <span class="black--text" v-text="title" />
    </div>
    <v-card :class="`${$vuetify.breakpoint.xs ? '' : 'rounded-xl'} elevation-4`">
      <v-data-table
        :item-class="() => 'v-data-table-row'"
        :headers="headers"
        :items="items"
        hide-default-footer
        :items-per-page="itemsPerPage"
      >
        <!-- slots -->
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" v-if="name"></slot>
        </template>
      </v-data-table>
      <div v-if="items.length > itemsPerPage" class="text-center pb-2">
        <span class="error--text">Se ha excedido el máximo de elementos mostrables</span>
      </div>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "AppSimpleTable",

  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header th {
  color: #000 !important;
}
::v-deep .v-data-table-row {
  &:nth-child(even) {
    background-color: #ffffff; // Fondo blanco para filas pares
  }

  &:nth-child(odd) {
    background-color: #e0e0e0; // Fondo gris para filas impares
  }

  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 15px;
      }
      &:last-child {
        border-bottom-right-radius: 15px;
      }
    }
  }
}

::v-deep .v-data-table-row:hover {
  background-color: #bdbdbd !important; // Fondo rojo para filas al pasar el ratón
  color: #fff;

  &:first-child {
    td {
      &:first-child {
        border-top-left-radius: 0px !important;
      }
      &:last-child {
        border-top-right-radius: 0px !important;
      }
    }
  }
  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 15px !important;
      }
      &:last-child {
        border-bottom-right-radius: 15px !important;
      }
    }
  }
}
</style>
